import React, { Component } from 'react';
import NewsItem from "./NewsItem/NewsItem";
import './News.css';

let newsItems = [
    {
        text: "Joe released his single, Hurricane, a cover of the original in \"Death Note: The Musical\"," +
            " in August 2023.",
        imagePublicId: 'Hurricane',
        redirectUrl: 'https://www.youtube.com/watch?v=d2NtvoCdQO0',
        linkType: 'VIDEO'
    },
    {
        text: "Joe played Torcher in Arcadia Collective's short film \"Jackman\"," +
            " released in January 2020.",
        imagePublicId: 'Jackman',
        redirectUrl: 'https://www.youtube.com/watch?v=Evc1DRbPz7M',
        linkType: 'VIDEO'
    },
    {
        text: "Joe was featured in the cast of \"She Loves Me\" at Austin Playhouse " +
            "from November 22nd to December 21st, 2019.",
        imagePublicId: 'SheLovesMeNoText',
        redirectUrl: 'https://www.austinplayhouse.com/she-loves-me',
        linkType: 'SITE'
    },
    {
        text: "Joe played Drake, Hull, and others in \"Annie\" at TexArts Association " +
            "from July 12th to August 4th, 2019.",
        imagePublicId: 'Annie',
        redirectUrl: 'https://www.tex-arts.org/professional/annie/',
        linkType: 'SITE'
    },
    {
        text: "Joe played Rapunzel's Prince in \"Into the Woods\" at the EmilyAnn Theatre " +
            "from May 31st to June 31st, 2019.",
        imagePublicId: 'IntoTheWoods',
        redirectUrl: 'http://www.emilyann.org/productions_theatre.html',
        linkType: 'SITE'
    }
];

class News extends Component {
    render() {
        return(
            <div>
                <div className="news">
                    <hr/>
                    <h2><b>News</b></h2>
                    <div>
                        {newsItems.map((newsItem, i) =>
                            <NewsItem
                                newsText={newsItem.text}
                                imagePublicId={newsItem.imagePublicId}
                                redirectUrl={newsItem.redirectUrl}
                                linkType={newsItem.linkType}
                                key={i}
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default News;
