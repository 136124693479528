import React, { Component } from 'react';
import "./AboutOuter.css";
import NameHeader from "../../Shared/NameHeader/NameHeader";
import Footer from "../../Footer/Footer";
import AboutInner from "../../About/AboutInner/AboutInner";
import Nav from "../../Shared/Nav/Nav";

class AboutOuter extends Component {
    render() {
        return(
            <div className="actingIndex">
                <NameHeader/>
                <Nav/>
                <AboutInner/>
                <Footer/>
            </div>
        );
    }
}

export default AboutOuter;