import React, { Component } from 'react';
import './Reels.css';
import NameHeader from "../../Shared/NameHeader/NameHeader";
import Nav from "../../Shared/Nav/Nav";
import Footer from "../../Footer/Footer";

class Reels extends Component {
    render() {
        return(
            <div>
                <NameHeader/>
                <Nav/>
                <div>
                    <h2 className="reel-header">Film Reel</h2>
                    <div className="reel-film">
                        <iframe
                            width="560"
                            height="315"
                            src="https://www.youtube.com/embed/9qsVPsjjQ2I"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Joseph Landis Film Reel 2020"
                        />
                    </div>
                    <h2 className="reel-header">Voice-over Reels</h2>
                    <div className="vo-reels-row">
                        <div className="vo-reels-labels">
                            <h4>Commercial:</h4>
                            <h4>Commercial-Character:</h4>
                            <h4>Gaming:</h4>
                            <h4>Animation:</h4>
                        </div>
                        <div className="vo-reels-audio">
                            <audio controls>
                                <source
                                    src="audio/JosephLandisVOCommercialDemo.mp3"
                                    type="audio/mpeg"
                                />
                            </audio>
                            <audio controls>
                                <source
                                    src="audio/JosephLandisVOCommercial-CharacterDemo.mp3"
                                    type="audio/mpeg"
                                />
                            </audio>
                            <audio controls>
                                <source
                                    src="audio/JosephLandisVOGamingDemo.mp3"
                                    type="audio/mpeg"
                                />
                            </audio>
                            <audio controls>
                                <source
                                    src="audio/JosephLandisVOAnimationDemo.mp3"
                                    type="audio/mpeg"
                                />
                            </audio>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Reels;