import React, { Component } from 'react';
import "./Home.scss";
import Footer from "../Footer/Footer";
import NameHeader from "../Shared/NameHeader/NameHeader";
import Nav from "../Shared/Nav/Nav";
import { Image } from 'cloudinary-react';
import News from "../News/News";

class Home extends Component {
    render() {
        return(
            <div id="home">
                <NameHeader/>
                <Nav/>
                <div id="mainImageContainer">
                    <Image cloudName="joelandismain" publicId="SongNightSinging" alt="Joseph Landis Singing"/>
                </div>
                <News/>
                <Footer/>
            </div>
        );
    }
}

export default Home;