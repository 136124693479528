import React, { Component } from 'react';
import './Gallery.css';
import NameHeader from "../../Shared/NameHeader/NameHeader";
import GalleryImage from "../GalleryImage/GalleryImage";
import Footer from "../../Footer/Footer";
import Nav from "../../Shared/Nav/Nav";

let cloudinaryImages = [
    {publicId: 'Annie_Drake', altText: 'Annie - Drake'},
    {publicId: 'SongNight_Cellophane1', altText: 'Mister Cellophane - Studio E Song Night'},
    {publicId: 'SongNight_Cellophane2', altText: 'Mister Cellophane - Studio E Song Night 2'},
    {publicId: 'Cabaret', altText: 'Cabaret'},
    {publicId: 'SongNight_King2', altText: 'Herod - Studio E Song Night'},
    {publicId: 'HenryV', altText: 'Henry V'},
    {publicId: 'JosephLandis_4', altText: 'Headshot 3'},
    {publicId: 'JosephLandis_6', altText: 'Headshot 5'},
    {publicId: 'JosephLandis_7', altText: 'Headshot 6'},
    {publicId: 'JosephLandis_5', altText: 'Headshot 4'},
    {publicId: 'JosephLandis_Hood', altText: 'Headshot 2'}
];

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadedImages: []
        }
    }

/*    onLoad(image) {
        this.setState(({ loadedImages }) => {
            return { loadedImages: loadedImages.concat(image) }
        })
    }*/

    render() {
        return(
            <div>
                <NameHeader/>
                <Nav/>
                <div className="gallery" ref={element => { this.galleryElement = element; }}>
                    <div className="images">
                        {cloudinaryImages.map((imageObj, i) =>
                            <GalleryImage imageUrl={imageObj.publicId} altText={imageObj.altText} handleStateChange={this.handleImageChange} key={i}/>
                        )}
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }

    handleImageChange = () => {
        this.setState({
            loading: !imagesLoaded(this.galleryElement)
        });
    };
}

function imagesLoaded(parentNode) {
    const imgElements = parentNode.querySelectorAll("img");
    for (const img of imgElements) {
        if(!img.complete) {
            return false;
        }
    }
    return true;
}

export default Gallery;