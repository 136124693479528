import React from 'react';
import './ImageModal.css';

const ImageModal = ({ handleClose, show, children}) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return(
        <div className={showHideClassName}>
            <section className="modal-main">
                {children}
                <div className="closeButton">
                    <svg width="50" height="50" onClick={handleClose}>
                        <path d="M0 0 L50 50 M50 0 L0 50"/>
                    </svg>
                </div>
            </section>
        </div>
    );
};

export default ImageModal;

