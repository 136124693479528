import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './GalleryImage.scss';
import ImageModal from "../../Shared/ImageModal/ImageModal";
import { Image } from 'cloudinary-react';

class GalleryImage extends Component {
    myCloudName = "joelandismain";

    constructor(props) {
        super(props);

        this.state = {
            imageUrl: this.props.imageUrl,
            altText: this.props.altText,
            handleStateChange: this.props.handleStateChange,
            show: false,
        };

        this.showImageModal = this.showImageModal.bind(this);
        this.hideImageModal = this.hideImageModal.bind(this);
    }

    showImageModal = () => {
        if(this.state.show === false) {
            this.setState({ show: true }, () => {
                console.log(this.state.show, 'show');
            });

            document.getElementById('mainHtml').style.overflowY = "hidden";
        }


    };

    hideImageModal = () => {
        this.setState({ show: false }, () => {
            console.log(this.state.show, 'show');
        });
        document.getElementById('mainHtml').style.overflowY = "";
    };

    render() {
        return(
            <span className="galleryImage" onClick={this.showImageModal}>
                <Image cloudName={this.myCloudName} publicId={this.state.imageUrl} alt={this.state.altText} onLoad={this.state.handleStateChange}
                onError={this.state.handleStateChange} onClick={this.showImageModal}/>
                <div className="after">
                    <div className="text"/>
                </div>
                <ImageModal show={this.state.show} handleClose={this.hideImageModal}>
                    <Image cloudName={this.myCloudName} publicId={this.state.imageUrl} alt ={this.state.altText}/>
                </ImageModal>
            </span>

        );
    }

}

GalleryImage.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
    handleStateChange: PropTypes.func.isRequired,
};

export default GalleryImage;