import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Nav.css';

class Nav extends Component {
    render() {
        return(
          <div className="nav">
              <ul>
                  <li><Link to="/about"><b>About</b></Link></li>
                  <li><Link to="/resume"><b>Resume</b></Link></li>
                  <li><Link to="/reels"><b>Reels</b></Link></li>
                  <li><Link to="/gallery"><b>Gallery</b></Link></li>
                  <li><Link to="/contact"><b>Contact</b></Link></li>
              </ul>
          </div>
        );
    }
}

export default Nav;