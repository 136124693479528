import React, { Component } from 'react';
import "./Footer.css";
import SocialIcon from "../Shared/SocialIcon/SocialIcon";

class Footer extends Component {
    render() {
        return(
            <div id="footerContainer">
                <div>
                    <ul>
                        <li>
                            <SocialIcon iconName="icon-instagram.svg" altText="My Instagram!"
                                        linkLoc="https://www.instagram.com/brosephmandis/"/>
                        </li>
                        <li>
                            <SocialIcon iconName="icon-linkedin.svg" altText="My LinkedIn!"
                                        linkLoc="https://www.linkedin.com/in/landisjoseph"/>
                        </li>
                        <li>
                            <SocialIcon iconName="icon-twitter.svg" altText="My Twitter!"
                                        linkLoc="https://twitter.com/Broseph_Mandis"/>
                        </li>
                        <li>
                            <SocialIcon iconName="icon-youtube.svg" altText="My YouTube!"
                                        linkLoc="https://www.youtube.com/channel/UC0hoS0ZKepkarBS1rt6J14w"/>
                        </li>
                        <li>
                            <SocialIcon iconName="icon-git.svg" altText="My GitLab!"
                                        linkLoc="https://gitlab.com/itilos"/>
                        </li>
                        <li>
                            <SocialIcon iconName="icon-stackoverflow.svg" altText="My StackOverflow!"
                                        linkLoc="http://stackoverflow.com/users/3492059/jestus"/>
                        </li>
                    </ul>
                </div>
                <div>
                    <b>Ⓒ Joseph Landis 2023</b>
                    <div className="reactDisclaimer">Website designed and developed by Joseph Landis in React and Node.</div>
                </div>
            </div>
        );
    }
}

export default Footer;