import React, { Component } from 'react';
import { Image } from 'cloudinary-react';
import PropTypes from 'prop-types';
import './NewsItem.css';

class NewsItem extends Component {
    myCloudName = "joelandismain";

    constructor(props) {
        super(props);

        this.state = {
            newsText: this.props.newsText,
            imagePublicId: this.props.imagePublicId,
            redirectUrl: this.props.redirectUrl,
            linkType: this.props.linkType
        }
    }

    render() {
        let linkText = ''
        if (this.state.linkType === 'VIDEO') {
            linkText = 'To watch it now, click'
        } else {
            linkText = 'For more information, click'
        }


        return(
            <div className="newsItem">
                <Image cloudName={this.myCloudName} publicId={this.state.imagePublicId}/>
                <p><b>{this.state.newsText}</b></p>
                <p>{linkText} <a href={this.state.redirectUrl}>HERE.</a></p>
            </div>
        );
    }
}

NewsItem.propTypes = {
    newsText: PropTypes.string.isRequired,
    imagePublicId: PropTypes.string.isRequired,
    redirectUrl: PropTypes.string.isRequired,
    linkType: PropTypes.string.isRequired
};

export default NewsItem;