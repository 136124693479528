import React, { Component } from 'react';
import './SocialIcon.css';

class SocialIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iconName: this.props.iconName,
            altText: this.props.altText,
            linkLoc: this.props.linkLoc
        }
    }

    render() {
        return(
            <a href={this.state.linkLoc}>
                <img alt={this.props.altText} src={"/images/icons/" + this.state.iconName}/>
            </a>
        );
    }
}

export default SocialIcon;