import React, { Component } from 'react';
import './AboutInner.css';
import { Image } from 'cloudinary-react';

class AboutInner extends Component {
    myCloudName = "joelandismain";

    render() {
        return(
            <div className="aboutContainer">
                <div className="aboutImage">
                    <Image cloudName={this.myCloudName} publicId="JosephLandis_3" alt="Joseph Landis"/>
                </div>
                <div className="aboutDesc">
                    <div className="aboutDescNameTitle">
                        <b>About Joe</b>
                    </div>
                    <p>
                    Joe is an American actor, singer, and software engineer. During his years in Austin, TX, he appeared
                        in shows such as <i>She Loves Me</i> at Austin Playhouse, TexARTS Association's <i>Annie</i>,
                        EmilyAnn Theatre's <i>Into the Woods</i>, The Baron's Men's <i>The Curate's As You Like It</i>
                        &nbsp;and <i>The 14/48 Projects</i> at Ground Floor Theatre.
                    </p>
                    <p>
                    Joe holds a Bachelor of Science in Computer Science from Purdue University in West Lafayette, IN, with a
                        Certificate in Acting. While at Purdue he was seen in shows such as <i>Cabaret</i>,&nbsp;
                        <i>The Last Days of Judas Iscariot</i>, <i>Henry V</i>, and <i>Crimes of The Heart</i>.
                    </p>
                    <p>
                        He originally grew up in Carmel, Indiana, lived for a half-decade in Austin, TX, and is now
                        living the dream in NYC.
                    </p>
                    <p>
                        Joe is currently represented in the Southwest market only by Callidus Agency.
                    </p>
                </div>
            </div>
        );
    }
}

export default AboutInner;
