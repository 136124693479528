import React, { Component } from 'react';
import './ActingResume.css';
import NameHeader from "../../Shared/NameHeader/NameHeader";
import Footer from "../../Footer/Footer";
import PDFViewer from "../../Shared/PDFViewer/PDFViewer";
import Nav from "../../Shared/Nav/Nav";

class ActingResume extends Component {
    render() {
        return(
            <div>
                <NameHeader/>
                <Nav/>
                <PDFViewer pdfUrl="/pdfs/JosephLandisTotalResume.pdf" altText="Joseph Landis Resume" imageUrl="/pdfs/JosephLandisTotalResume.jpg"/>
                <Footer/>
            </div>
        );
    }
}

export default ActingResume;