import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import Home from "./components/Home/Home";
import ActingResume from "./components/Acting/ActingResume/ActingResume";
import Gallery from "./components/Acting/Gallery/Gallery";
import Contact from "./components/Acting/Contact/Contact";
import AboutOuter from "./components/About/AboutOuter/AboutOuter";
import Reels from "./components/Acting/Reels/Reels";

class App extends Component {
    render() {
        return (
            <Switch>
                <Route path="/contact" component={Contact}/>
                <Route path="/gallery" component={Gallery}/>
                <Route path="/reels" component={Reels}/>
                <Route path="/resume" component={ActingResume}/>
                <Route path="/about" component={AboutOuter}/>
                <Route path="/" component={Home}/>
            </Switch>
        );
    }
}

export default App;


/*class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0
    };
  }

  changePageState = (state) => {
    this.setState({
        currentPage: state
    });
  };

  render() {
    switch(this.state.currentPage) {
        case 0:
          return(<Home changePageState={this.changePageState}/>);
        case 1:
          return (<About changePageState={this.changePageState}/>);
        case 2:
          return(<EngineeringIndex changePageState={this.changePageState}/>);
        case 3:
          return(<Performances changePageState={this.changePageState}/>);
        case 4:
          return(<TechResume changePageState={this.changePageState}/>);
        case 5:
          return(<ActingResume changePageState={this.changePageState}/>);
        default:
            return(<Home changePageState={this.changePageState}/>);
    }

  }
}

export default App;*/
