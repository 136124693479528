import React, { Component } from 'react';
import NameHeader from "../../Shared/NameHeader/NameHeader";
import Footer from "../../Footer/Footer";
import './Contact.css';
import Nav from "../../Shared/Nav/Nav";
import { Image } from 'cloudinary-react';
const website_base_url = 'https://node-email-server.herokuapp.com/api';

class Contact extends Component {
    myCloudName = "joelandismain";

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: ''
        };

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleSubjectChange = this.handleSubjectChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.submitContact = this.submitContact.bind(this);
        Contact.postRequest = Contact.postRequest.bind(this);
    }

    static postRequest(name, email, subject, message) {
        fetch(website_base_url + '/sendEmail', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
                email: email,
                subject: subject,
                message: message
            })
        }).then((response) => {
            console.log('Email sent: ', response.statusText);
            Contact.emailSentTextVisible();
        }).catch((error) => {
            console.log('Error: ', error);
        })
    }

    static emailSentTextVisible() {
        document.getElementById("emailSentText").className = "emailSent show";
    }

    submitContact(e) {
        e.preventDefault();
        Contact.postRequest(this.state.name, this.state.email, this.state.subject, this.state.message);
        this.setState({
            name: '',
            email: '',
            subject: '',
            message: ''
        })
    }

    handleNameChange(e) {
        this.setState({
            name: e.target.value
        });
    }

    handleEmailChange(e) {
        this.setState({
            email: e.target.value
        });
    }

    handleSubjectChange(e) {
        this.setState({
            subject: e.target.value
        });
    }

    handleMessageChange(e) {
        this.setState({
            message: e.target.value
        });
    }

    render() {
        return(
            <div>
                <NameHeader/>
                <Nav/>
                <div className="contactForm">
                    <h2>Agency</h2>
                    <div className="agencyInfo">
                        <Image cloudName={this.myCloudName} publicId="CallidusLogo" alt="Agency Info"/>
                        <a href="http://www.callidusagency.com/"><p>http://www.callidusagency.com</p></a>
                    </div>
                    <div className="directContact">
                        <h4>Direct</h4>
                        <form onSubmit={this.submitContact}>
                            <div className="row">
                                <div className="col">
                                    <input className="contactText" type="text" name="name" placeholder="Name"
                                           value={this.state.name} onChange={this.handleNameChange}/>
                                    <input className="contactText" type="email" name="email" placeholder="Email"
                                           value={this.state.email} onChange={this.handleEmailChange}/>
                                    <input className="contactText" type="text" name="subject" placeholder="Subject"
                                           value={this.state.subject} onChange={this.handleSubjectChange}/>
                                </div>
                                <div className="col">
                                    <textarea className="contactText" name="message" placeholder="Message"
                                              value={this.state.message} onChange={this.handleMessageChange}/>
                                    <button className="contactButton" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div id="emailSentText" className="emailSent hidden">
                            Your email has been sent! Thanks for reaching out.
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Contact;