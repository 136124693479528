import React from 'react';
import './PDFViewer.css';

const PDFViewer = ({pdfUrl, altText, imageUrl}) => {
    if(!isMobile()) {
        return (
            <div className="pdfContainer">
                <embed src={pdfUrl} width="600" height="800" alt={altText}
                       pluginspage="http://www.adobe.com/products/acrobat/readstep2.html"/>
            </div>
        );
    } else {
        return(
            <div className="pdfContainer">
                <img src={imageUrl} alt={altText}/>
            </div>
        );
    }
};

function  isMobile() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

export default PDFViewer;