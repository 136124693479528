import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './NameHeader.css';

class NameHeader extends Component {
    render() {
        return(
            <div className="name-title-wrapper">
                <Link to="/">
                    <div className="nameAndSubtitle">
                        <h1>Joseph Landis</h1>
                        <h4>Actor - Software Engineer - Game Dev</h4>
                    </div>
                </Link>
            </div>
        );
    }
}

export default NameHeader;